import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'



import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'




const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | Pay | Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>ECOMMERCE <span> FEATURES</span></h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Features <span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Features</h3> 
                    <h2>ECOMMERCE <span> FEATURES</span></h2>
                    
                </div>
            
                <div className="custom-inner-holder  mar-bottom " >
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-feature-1-icon"></i>
                                    <h3>LIVE DASHBOARD</h3>
                                </div>
                              
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Live dashboard for tracking key parameters for effective status tracking and decision making. The dashboard can be customized to meet your exact needs as part of the implementation and configuration process.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-2-icon"></i>
                                    <h3>PAYMENT GATEWAY NEUTRAL</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Integrate with any payment gateway.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-3-icon"></i>
                                    <h3>MANAGE USER ACCESS AND ROLES</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Define your staff and management as per your need.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-4-icon"></i>
                                    <h3>COMPREHENSIVE REPORTS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Bespoke reports to meet your exact needs including export options to facilitate detailed analysis.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-5-icon"></i>
                                    <h3>3RD PARTY APPLICATION INTEGRATION</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Pay architecture enables integration with any 3rd party application.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-6-icon"></i>
                                    <h3>ACCESS MANAGEMENT</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Pay platform access is fully managed through user-role access.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    

                
                    


                </div>
               

           

                       
            </div>
            <div className="sec-lines"></div>
        </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
